import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavbarBrand from 'react-bootstrap/NavbarBrand';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import './App.css';
import Routes from './Routes';

function App() {
  return (
    <div className="App container">
      <Navbar fluid collapseOnSelect fixed="top" bg="dark" variant="dark">
        <LinkContainer to="/">
          <NavbarBrand href="/">ChrisWessells.cloud</NavbarBrand>
        </LinkContainer>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <LinkContainer to="/signup">
              <Nav.Link href="/signup">Signup</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/login">
              <Nav.Link href="/login">Login</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Routes />
    </div>
  );
}

export default App;

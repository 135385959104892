import React from 'react';
import './Home.css';

export default function Home() {
    return (
        <div className="Home">
            <div className="lander">
                <h1>Scratch</h1>
                <p>A simple not taking app</p>
            </div>
        </div>
    );
}